import { default as _91id_93NiBmJWQ7FoMeta } from "/home/forge/merchants.smake.com/releases/v1.34.0/pages/[slug]/catalog/logos/[id].vue?macro=true";
import { default as indexGTmpr0TF7iMeta } from "/home/forge/merchants.smake.com/releases/v1.34.0/pages/[slug]/catalog/logos/index.vue?macro=true";
import { default as _91id_93DrpPmZCg5uMeta } from "/home/forge/merchants.smake.com/releases/v1.34.0/pages/[slug]/customers/[customer]/logos/[id].vue?macro=true";
import { default as indexCsg3xtdFPPMeta } from "/home/forge/merchants.smake.com/releases/v1.34.0/pages/[slug]/customers/[customer]/logos/index.vue?macro=true";
import { default as colors01XGbAJ0nJMeta } from "/home/forge/merchants.smake.com/releases/v1.34.0/pages/[slug]/customers/[id]/colors.vue?macro=true";
import { default as favorite_45variantsmi7gGNp7OzMeta } from "/home/forge/merchants.smake.com/releases/v1.34.0/pages/[slug]/customers/[id]/favorite-variants.vue?macro=true";
import { default as indexNhqB3aahNMMeta } from "/home/forge/merchants.smake.com/releases/v1.34.0/pages/[slug]/customers/[id]/index.vue?macro=true";
import { default as indexEBv25ivEDYMeta } from "/home/forge/merchants.smake.com/releases/v1.34.0/pages/[slug]/customers/index.vue?macro=true";
import { default as dashboard6sI2cDLEOaMeta } from "/home/forge/merchants.smake.com/releases/v1.34.0/pages/[slug]/dashboard.vue?macro=true";
import { default as entrance8sT2X0uwRsMeta } from "/home/forge/merchants.smake.com/releases/v1.34.0/pages/[slug]/entrance.vue?macro=true";
import { default as indexdbdyZRC2mVMeta } from "/home/forge/merchants.smake.com/releases/v1.34.0/pages/[slug]/index.vue?macro=true";
import { default as loginyx3XZHkRPJMeta } from "/home/forge/merchants.smake.com/releases/v1.34.0/pages/[slug]/login.vue?macro=true";
import { default as indexV6u8oCKdKPMeta } from "/home/forge/merchants.smake.com/releases/v1.34.0/pages/index.vue?macro=true";
export default [
  {
    name: "slug-catalog-logos-id",
    path: "/:slug()/catalog/logos/:id()",
    meta: _91id_93NiBmJWQ7FoMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.34.0/pages/[slug]/catalog/logos/[id].vue")
  },
  {
    name: "slug-catalog-logos",
    path: "/:slug()/catalog/logos",
    meta: indexGTmpr0TF7iMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.34.0/pages/[slug]/catalog/logos/index.vue")
  },
  {
    name: "slug-customers-customer-logos-id",
    path: "/:slug()/customers/:customer()/logos/:id()",
    meta: _91id_93DrpPmZCg5uMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.34.0/pages/[slug]/customers/[customer]/logos/[id].vue")
  },
  {
    name: "slug-customers-customer-logos",
    path: "/:slug()/customers/:customer()/logos",
    meta: indexCsg3xtdFPPMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.34.0/pages/[slug]/customers/[customer]/logos/index.vue")
  },
  {
    name: "slug-customers-id-colors",
    path: "/:slug()/customers/:id()/colors",
    meta: colors01XGbAJ0nJMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.34.0/pages/[slug]/customers/[id]/colors.vue")
  },
  {
    name: "slug-customers-id-favorite-variants",
    path: "/:slug()/customers/:id()/favorite-variants",
    meta: favorite_45variantsmi7gGNp7OzMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.34.0/pages/[slug]/customers/[id]/favorite-variants.vue")
  },
  {
    name: "slug-customers-id",
    path: "/:slug()/customers/:id()",
    meta: indexNhqB3aahNMMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.34.0/pages/[slug]/customers/[id]/index.vue")
  },
  {
    name: "slug-customers",
    path: "/:slug()/customers",
    meta: indexEBv25ivEDYMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.34.0/pages/[slug]/customers/index.vue")
  },
  {
    name: "slug-dashboard",
    path: "/:slug()/dashboard",
    component: () => import("/home/forge/merchants.smake.com/releases/v1.34.0/pages/[slug]/dashboard.vue")
  },
  {
    name: "slug-entrance",
    path: "/:slug()/entrance",
    meta: entrance8sT2X0uwRsMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.34.0/pages/[slug]/entrance.vue")
  },
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/home/forge/merchants.smake.com/releases/v1.34.0/pages/[slug]/index.vue")
  },
  {
    name: "slug-login",
    path: "/:slug()/login",
    meta: loginyx3XZHkRPJMeta || {},
    component: () => import("/home/forge/merchants.smake.com/releases/v1.34.0/pages/[slug]/login.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/merchants.smake.com/releases/v1.34.0/pages/index.vue")
  }
]